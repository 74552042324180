<template>
  <div class="swiper-container">
    <div class="swiper-wrapper banner">
      <!-- 增加懒加载 -->
      <div
        class="swiper-slide"
        v-for="no in 4"
        :key="no"
        style="min-height: 590px"
      >
        <img
          :data-src="require(`assets/image/home_banner/banner${no}.png`)"
          class="swiper-lazy"
        />
        <div class="swiper-lazy-preloader" />
      </div>
    </div>
    <div class="swiper-pagination" />
  </div>
  <div class="news-block">
    <block-title main-title="新闻资讯" sub-title="News" />
    <div class="content">
      <div class="left-content swiper-news">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="newsItem in newsList"
            :key="newsItem?.title"
          >
            <img :src="newsItem?.thumb" :alt="newsItem?.title" />
            <div class="time-stamp">
              {{ newsItem?.update_time.split(" ")[0] }}
            </div>
            <div class="news-title">
              {{ newsItem?.title }}
            </div>
          </div>
        </div>

        <div class="cut-button">
          <i
            class="iconfont icon-arrow-left-bold left-arrow"
            @click="leftArrow"
          ></i>
          <i
            class="iconfont icon-arrow-right-bold right-arrow"
            @click="rightArrow"
          ></i>
        </div>
      </div>
      <div class="right-content">
        <ul class="list-box">
          <li
            v-for="item in newsList"
            :key="item.title"
            @click="router.replace('/ComCondition?isHome=1&newsId=' + item.id)"
          >
            <div class="time-stamp">{{ item.update_time.split(" ")[0] }}</div>
            <p class="list-title">{{ item.title }}</p>
          </li>
        </ul>
        <div
          class="view-more-button"
          @click="router.push('/ComCondition?isHome=1')"
        >
          查看更多
        </div>
      </div>
    </div>
  </div>
  <div class="main-business-banner">
    <div class="banner-bg">
      <p class="main-title">主营业务</p>
      <p class="sub-title">生态环境监测与咨询服务提供商</p>
      <ul class="main-title-list">
        <li>
          <span>10年+</span>
          <span>气象、环境领域技术积累</span>
        </li>
        <li>
          <span>20项+</span>
          <span>研发核心软件及硬件产品</span>
        </li>
        <li>
          <span>100+</span>
          <span>服务全国100+气象环保部门</span>
        </li>
        <li>
          <span>30000时</span>
          <span>产品系统可持续稳定运行</span>
        </li>
      </ul>
    </div>
  </div>

  <ul class="page-navigation">
    <li @click="goToPath('/Hardware')">
      <i class="icon product" />
      <h4>大气环境</h4>
      <p class="content-text">
        基于三十余年大气污染防治工作中的实绩应用，浦蓝研究院在大气环境监测、大气环境模拟、数据集成应用等方面有着丰富的软、硬件产品以及成功案例。
      </p>
      <div class="look-detail">查看详情</div>
    </li>
    <li @click="goToPath('/Ecotope')">
      <i class="icon program" />
      <h4>绿色发展</h4>
      <p class="content-text">
        以丰富的生态-大气研究成果和气候变化与可持续发展学科为支撑，浦蓝研究院致力打造生态系统感知、生态价值评估、低碳路径规划等面向国家绿色...
      </p>
      <div class="look-detail">查看详情</div>
    </li>
    <li @click="goToPath('/SucCase')">
      <i class="icon service" />

      <h4>智慧气象</h4>
      <p class="content-text">
        当百余年传统气象学科遇上新兴科技人工智能技术，浦蓝研究院创新地研发出适用于政府、航空、海洋等场景的智慧气象产品及定制化服务...
      </p>
      <div class="look-detail">查看详情</div>
    </li>
    <li @click="goToPath('/ParticulateMatter')">
      <i class="icon success" />

      <h4>咨询服务</h4>
      <p class="content-text">
        浦蓝研究院面向全行业客户提供基于但不限于气候变化、大气科学范围内的专业技术服务、方案设计以及解决方案，竭诚为客户提供专业、贴身的“一站...
      </p>
      <div class="look-detail">查看详情</div>
    </li>
  </ul>
</template>

<script>
import BlockTitle from "components/BlockTitle.vue";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { onMounted, ref } from "vue";
import { sendMailApi } from "api/home";
import { vaildateEmail } from "util";
import { ElMessage } from "element-plus";
import { getNewssListApi } from "api/new";
import cloneDeep from "lodash/cloneDeep";
import { useRouter } from "vue-router";
export default {
  name: "Main",
  components: { BlockTitle },
  setup() {
    //发送邮件功能
    const emptyForm = {
      name: "",
      email: "",
      contact: "",
    };
    const emptyVaild = {
      name: undefined,
      email: undefined,
    };
    const form = ref(cloneDeep(emptyForm));
    const formVaild = ref(cloneDeep(emptyVaild));
    async function sendEmail() {
      formVaild.value = cloneDeep(emptyVaild);
      if (form.value.name == "") {
        formVaild.value.name = "姓名输入有误";
      }
      if (form.value.email == "" || !vaildateEmail(form.value.email)) {
        formVaild.value.email = "输入有效的电子邮件地址";
      }

      const allTrue = Object.keys(formVaild.value).every(
        (key) => !formVaild.value[key]
      );
      if (allTrue) {
        const data = await sendMailApi(form.value);
        if (data.data.code == 0) {
          ElMessage.success({
            message: "发送成功",
            type: "success",
          });
          form.value = cloneDeep(emptyForm);
        } else {
          ElMessage.error("发送失败");
        }
      }
    }

    //轮播图功能
    onMounted(() => {
      new Swiper(".swiper-container", {
        speed: 300,
        effect: "fade",
        autoplay: {
          disableOnInteraction: false,
          delay: 10000, //1秒切换一次
        },
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 1,
        },
        fadeEffect: {
          crossFade: true,
        },
        allowTouchMove: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    });

    // 新闻资讯功能
    const newsList = ref([]);
    async function getNewsList() {
      const result = await getNewssListApi({
        type: 2,
        pageSize: 5,
        pageNum: 1,
      });
      newsList.value = result?.data?.data;
    }
    getNewsList();

    let swiperNews = ref(null);
    onMounted(() => {
      swiperNews.value = new Swiper(".swiper-news", {
        speed: 300,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        // observeParents: true, //修改swiper的父元素时，自动初始化swiper
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        allowTouchMove: false,
        autoplay: {
          disableOnInteraction: false,
          delay: 10000, //1秒切换一次
        },
      });
    });

    const rightArrow = () => swiperNews.value.slideNext();
    const leftArrow = () => swiperNews.value.slidePrev();

    const router = useRouter();

    const goToPath = (path) => router.push({ path, query: { isHome: true } });
    return {
      form,
      formVaild,
      sendEmail,
      newsList,
      leftArrow,
      rightArrow,
      router,
      goToPath,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/css/page/home.scss";
.swiper-container {
  --swiper-pagination-color: #fff; /* 两种都可以 */
}
// .input-box {
//   position: relative;
//   border: 1px solid #cecece;

//   .vaild-box {
//     position: absolute;
//     right: 10px;
//     top: 0;
//     line-height: 50px;
//     height: 50px;
//     color: red;
//     z-index: 999;
//     font-size: 14px;
//     font-weight: 700;
//     user-select: none;
//   }
// }
// .vaild-border {
//   border: 1px solid red;
// }
</style>
