<template>
  <img
    src="~assets/image/tab_banner/thumbnail/about_us_thumbnail.jpg"
    alt="关于我们"
    class="page-banner"
    style="width: 100%"
    v-lazyLoadImage="require('assets/image/tab_banner/about_us.png')"
  />
  <div class="content">
    <div class="left-nav">
      <tab-menu :menu-list="menuList" />
      <ul class="call-us">
        <li class="title">联系我们</li>
        <li><i class="iconfont icon-dianhua"></i>025-58225630</li>
        <li><i class="iconfont icon-email"></i>info@nagr.con.cn</li>
        <li><i class="iconfont icon-dizhi"></i>南京市浦口区浦滨...</li>
      </ul>
    </div>
    <div class="right-contact">
      <div class="title-box">当前位置：首页 > 关于我们 > {{ title_name }}</div>
      <router-view />
    </div>
  </div>
</template>

<script>
import TabMenu from "components/TabMenu.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
const menuList = [
  {
    name: "研究院简介",
    uri: "/Introduction",
  },
  {
    name: "人才团队",
    uri: "/Support",
  },
  {
    name: "技术支撑",
    uri: "/CoreTechnology",
  },
  {
    name: "资质荣誉",
    uri: "/Honor",
  },
  {
    name: "企业文化",
    uri: "/EnterCulture",
  },
];
export default {
  components: { TabMenu },
  name: "Business",
  setup() {
    const route = useRoute();
    const title_name = computed(
      () => menuList.find((item) => item.uri == route.path)?.name
    );
    return { title_name, menuList };
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-height: 1048px;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 30px 0 80px 0;
  box-sizing: border-box;
  .left-nav {
    width: 230px;
    background-color: #f0f5fa;
    .call-us {
      width: 100%;
      height: 230px;
      display: flex;
      flex-direction: column;
      border: 1px solid #d5d5d5;
      margin-top: 58px;
      background-color: #fff;
      li {
        flex: 1;
        font-size: 16px;
        color: #666666;
        font-weight: 700;
        padding-left: 19px;
        line-height: 57px;
        .iconfont {
          margin-right: 5px;
        }
        &.title {
          color: #167eb5;
          font-size: 20px;
          // font-family: Alibaba-PuHuiTi-Bold;
          font-weight: 700;
        }
        & + li {
          border-top: 1px solid #d5d5d5;
        }
      }
    }
  }
  .right-contact {
    width: 930px;
    min-height: 938px;
    margin-left: 40px;
    background-color: #fff;
    border: 1px solid #d5d5d5;
    .title-box {
      padding: 12px 0 12px 32px;
      border-bottom: 1px solid #f4f4f4;
      color: #222222;
      font-weight: 700;
      position: relative;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.02) 0%,
        rgba(128, 128, 128, 0.1) 100%
      );
      &::after {
        content: "";
        height: 17px;
        width: 5px;
        position: absolute;
        left: 17px;
        top: 12px;
        background: #0482dc;
      }
    }
  }
}
::v-deep(.el-menu-vertical-demo > div > .el-menu-item) {
  text-align: center;
}
</style>
>
