<template>
  <div class="title">
    <i class="title-icon-left" />
    <h3>{{ mainTitle }}</h3>
    <i class="title-icon-right" />
    <h4>{{ subTitle }}</h4>
  </div>
</template>

<script>
export default {
  props: ["mainTitle", "subTitle"],
};
</script>

<style lang="scss" scoped>
.title {
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  .title-icon-left {
    display: inline-block;
    width: 14.79px;
    height: 16.62px;
    background: url("../assets/image/title_icon_left.png") no-repeat;
  }
  .title-icon-right {
    display: inline-block;
    width: 14.79px;
    height: 16.62px;
    background: url("../assets/image/title_icon_right.png") no-repeat;
  }
  h3 {
    font-size: 30px;
    display: inline-block;
    margin: 0 18px;
  }
  h4 {
    display: inline-block;
    margin-left: 18px;
    margin-top: 10px;
  }
}
</style>
